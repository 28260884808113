import axios from "axios";
import config from "../../config/config";

const BASE_URL = config.REACT_APP_BASE_URL;

const GET_USER_URL = "public/api/auth/getUser";

const getUser = async (token) => {
  const resp = await axios.get(`${BASE_URL}${GET_USER_URL}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const CHECK_USER = "public/api/auth/sendotp";

const checkUser = async (data) => {
  const resp = await axios.post(`${BASE_URL}${CHECK_USER}`, data, {
    "Content-Type": "multipart/form-data",
  });

  return resp.data;
};

const VERIFY_OTP = "public/api/auth/verifyotp";

const verifyOtp = async (data) => {
  const resp = await axios.post(`${BASE_URL}${VERIFY_OTP}`, data, {
    "Content-Type": "multipart/form-data",
  });
  if (resp) {
    localStorage.setItem("ThrillFactoryAccessToken", resp.data.token);
  }
  return resp.data;
};

const REGISTER_URL = "public/api/auth/register";

const register = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${REGISTER_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const UPDATE_URL = "public/api/auth/updateUser";

const update = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${UPDATE_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const ASSEST_UPLOAD_URL = "public/api/auth/uploadimage";

const updateUserProfile = async (file, token) => {
  let data = new FormData();
  data.append("image", file);
  const resp = await axios.post(`${BASE_URL}${ASSEST_UPLOAD_URL}`, data, {
    headers: { Authorization: token },
  });
  return resp.data;
};

const authServices = {
  getUser,
  checkUser,
  verifyOtp,
  register,
  update,
  updateUserProfile,
};

export default authServices;
