import { React, lazy } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

//lazy loading
const Home = lazy(() => import("./pages/Home"));
const Cart = lazy(() => import("./pages/Cart"));
const AboutUs = lazy(() => import("./pages/AboutUs2"));
const Redirect = lazy(() => import("./pages/Redirect"));
const AllBlogs = lazy(() => import("./pages/AllBlogs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Blog = lazy(() => import("./components/blog/Blog"));
const Confirmation = lazy(() => import("./pages/Confirmation"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const ExploreGallery = lazy(() => import("./pages/ExploreGallery"));
const ReturnAndRefund = lazy(() => import("./pages/ReturnAndRefund"));
const TermsAndCondition = lazy(() => import("./pages/TermsAndCondition"));
const ActivityDetails = lazy(() => import("./pages/ActivityDetails"));

const RoutesWrapper = () => {
  const location = useLocation();
  let metaData = useSelector((state) => state.activities.metaDetails);
  let metaStatus = useSelector((state) => state.activities.metaStatus);
  if (metaStatus === "success") {
    metaData = metaData.filter((item) => item?.routeName === location.pathname);
  }

  return (
    <Routes>
      <Route
        path="/"
        element={metaData[0]?.routeName === "/" ? <Home data={metaData[0]} /> : <Home />}
      />
      <Route path="/activity/:slug" element={<ActivityDetails />} />
      <Route
        path="/cart"
        element={metaData[0]?.routeName === "/cart" ? <Cart data={metaData[0]} /> : <Cart />}
      />
      <Route path="/:id" element={<Redirect />} />
      <Route
        path="/about"
        element={metaData[0]?.routeName === "/about" ? <AboutUs data={metaData[0]} /> : <AboutUs />}
      />
      <Route
        path="/contact"
        element={
          metaData[0]?.routeName === "/contact" ? <ContactUs data={metaData[0]} /> : <ContactUs />
        }
      />
      <Route
        path="/gallery"
        element={
          metaData[0]?.routeName === "/gallery" ? (
            <ExploreGallery data={metaData[0]} />
          ) : (
            <ExploreGallery />
          )
        }
      />
      <Route
        path="/privacy-policy"
        element={
          metaData[0]?.routeName === "/privacy-policy" ? (
            <PrivacyPolicy data={metaData[0]} />
          ) : (
            <PrivacyPolicy />
          )
        }
      />
      <Route
        path="/return-&-refund"
        element={
          metaData[0]?.routeName === "/return-&-refund" ? (
            <ReturnAndRefund data={metaData[0]} />
          ) : (
            <ReturnAndRefund />
          )
        }
      />
      <Route path="/confirmation/:orderId" element={<Confirmation />} />
      <Route
        path="/terms-&-condition"
        element={
          metaData[0]?.routeName === "/terms-&-condition" ? (
            <TermsAndCondition data={metaData[0]} />
          ) : (
            <TermsAndCondition />
          )
        }
      />
      <Route path="/blogs">
        <Route
          path="all"
          element={
            metaData[0]?.routeName === "/blogs/all" ? <AllBlogs data={metaData[0]} /> : <AllBlogs />
          }
        />
        <Route path=":slug" element={<Blog />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default RoutesWrapper;
