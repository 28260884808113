import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ratingServices from "../services/ratingService";

const initialState = {
  rating: {},
  cancel: "",
  status: "loading",
  message: "",
};

export const sendRating = createAsyncThunk("post/sendRating", async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.accessToken;
    return await ratingServices.sendRating(token, data);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const cancelFeedback = createAsyncThunk("put/cancelFeedback", async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.accessToken;
    return await ratingServices.cancelFeedback(token, data);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

const ratingSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendRating.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendRating.fulfilled, (state, action) => {
        state.status = "success";
        state.rating = action.payload.result;
      })
      .addCase(sendRating.rejected, (state, action) => {
        state.status = "rejected";
        state.rating = {};
        // state.message = action.payload.message;
      })
      .addCase(cancelFeedback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cancelFeedback.fulfilled, (state, action) => {
        state.status = "success";
        state.cancel = action.payload.message;
      })
      .addCase(cancelFeedback.rejected, (state, action) => {
        state.status = "rejected";
        state.cancel = action.payload.message;
      });
  },
});

export const ratingAction = ratingSlice.actions;

export default ratingSlice.reducer;
