import { createSlice, createAsyncThunk, /* current */ } from "@reduxjs/toolkit";
import bookingService from "../services/bookingService";

const initialState = {
  id: "",
  booking: {},
  allBookings: [],
  status: "loading",
  message: "",
};

export const fetchBookings = createAsyncThunk("get/allBooking", async (data, thunkAPI) => {
  try {
    const token =
      thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
    return await bookingService.fetchBookings(token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const fetchOneBooking = createAsyncThunk("get/oneBooking", async (data, thunkAPI) => {
  try {
    const token =
      thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
    return await bookingService.fetchOneBooking(data, token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const newBooking = createAsyncThunk("add/newBooking", async (data, thunkAPI) => {
  try {
    const token =
      thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
    return await bookingService.newBooking(token, data);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const getCurrBooking = createAsyncThunk("get/currBooking", async (data, thunkAPI) => {
  try {
    const token =
      thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
    return await bookingService.getCurrBooking(token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

// export const updateBooking = createAsyncThunk("update/booking", async (data, thunkAPI) => {
//   try {
//     const token = thunkAPI.getState().auth.accessToken || localStorage.getItem("accessToken");
//     return await bookingService.updateBooking(data, token);
//   } catch (e) {
//     const msg =
//       (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
//     return thunkAPI.rejectWithValue(msg);
//   }
// });

export const cancelBooking = createAsyncThunk("delete/booking", async (data, thunkAPI) => {
  try {
    const token =
      thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
    return await bookingService.cancelBooking(data, token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

const bookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    setBooking: (state, action) => {
      state.booking = action.payload;
    },

    increasePackage: (state, action) => {
      const act = action.payload;
      const actExist = state.booking.items.find((item) => item.activity_id === act.id);
      if (actExist) {
        actExist.person += 1;
        state.booking.totalAmount += act.price;
      } else {
        return;
      }
    },

    decreasePackage: (state, action) => {
      const act = action.payload;
      const actExist = state.booking.items.find((item) => item.activity_id === act.id);
      if (!actExist) {
        return;
      } else if (actExist.person === 1) {
        return;
      } else {
        actExist.person -= 1;
        state.booking.totalAmount -= act.price;
      }
    },

    clearBooking: (state) => {
      state.booking = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookings.pending, (state) => {
        state.status = "loading";
        state.message = "Getting Your Bookings";
      })
      .addCase(fetchBookings.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Your Bookings";
        state.allBookings = action.payload.result;
      })
      .addCase(fetchBookings.rejected, (state, action) => {
        state.status = "rejected";
        state.message = action.payload;
      })
      .addCase(fetchOneBooking.pending, (state) => {
        state.status = "loading";
        state.message = "Getting Paid Booking";
      })
      .addCase(fetchOneBooking.fulfilled, (state, action) => {
        state.status = "success";
        state.booking = action.payload.result;
        state.message = action.payload.message;
      })
      .addCase(fetchOneBooking.rejected, (state, action) => {
        state.status = "rejected";
        state.message = action.payload.message;
      })
      .addCase(newBooking.pending, (state) => {
        state.status = "loading";
        state.message = "Please Wait We're Adding Your Booking";
      })
      .addCase(newBooking.fulfilled, (state, action) => {
        state.status = "success";
        state.id = action.payload._id;
        state.message = action.payload.message;
      })
      .addCase(newBooking.rejected, (state, action) => {
        state.status = "rejected";
        state.message = action.payload;
      })
      .addCase(getCurrBooking.pending, (state) => {
        state.status = "loading";
        state.message = "Fetching Current Booking";
      })
      .addCase(getCurrBooking.fulfilled, (state, action) => {
        state.status = "success";
        state.booking = action.payload.result;
        state.message = action.payload.message;
      })
      .addCase(getCurrBooking.rejected, (state, action) => {
        state.status = "rejected";
        state.message = action.payload;
      });
  },
});

export const { clearBooking, setBooking, increasePackage, decreasePackage } = bookingSlice.actions;

export default bookingSlice.reducer;
