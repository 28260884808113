import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import activitiesReducer from "./slices/activitiesSlice";
import cartReducer from "./slices/cartSlice";
import bookingReducer from "./slices/bookingSlice";
import ratingReducer from "./slices/ratingSlice";

const store = configureStore({
  reducer: {
    activities: activitiesReducer,
    auth: authReducer,
    cart: cartReducer,
    booking: bookingReducer,
    rating: ratingReducer,
  },
});

export default store;
