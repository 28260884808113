import axios from "axios";
import config from "../../config/config";

const BASE_URL = config.REACT_APP_BASE_URL;
const RATING_URL = "public/api/feedback";
const CANCEL_URL = "public/api/feedback/cancel";

const sendRating = async (token, data) => {
  const resp = await axios.post(`${BASE_URL}${RATING_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const cancelFeedback = async (token, data) => {
  const resp = await axios.put(`${BASE_URL}${CANCEL_URL}`, data, {
    headers: {
      Authorization: `${token}`,
    },
  });
  return resp.data;
};

const ratingServices = {
  sendRating,
  cancelFeedback,
};

export default ratingServices;
