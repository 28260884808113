import axios from "axios";
/* import config from "../../config/config"; */

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ACTIVITY_URL = "public/v2/api/activity";

const getAllActivities = async () => {
  const resp = await axios.get(`${BASE_URL}${ACTIVITY_URL}`);
  return resp.data;
};

const META_DETAILS_URL = "public/v2/api/activity/metadetail/detail";

const getAllMetaDetails = async () => {
  const resp = await axios.get(`${BASE_URL}${META_DETAILS_URL}`);
  return resp.data;
};

const HOTOFFER_URL = "public/api/hotoffer";

const getHotOffers = async () => {
  const resp = await axios.get(`${BASE_URL}${HOTOFFER_URL}`);
  return resp.data;
};

const GALLERY_IMAGES_URL = "public/api/gallery";

const getGalleryImages = async () => {
  const resp = await axios.get(`${BASE_URL}${GALLERY_IMAGES_URL}`);
  return resp.data;
};

const BANNER_IMAGES_URL = "public/api/banner";

const getBannerImages = async () => {
  const resp = await axios.get(`${BASE_URL}${BANNER_IMAGES_URL}`);
  return resp.data;
};

const services = {
  getHotOffers,
  getBannerImages,
  getGalleryImages,
  getAllActivities,
  getAllMetaDetails,
};

export default services;
