import axios from "axios";
import config from "../../config/config";

const BASE_URL = config.REACT_APP_BASE_URL;

const BOOKING_URL = "public/api/booking";

const fetchBookings = async (token) => {
  const resp = await axios.get(`${BASE_URL}${BOOKING_URL}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const fetchOneBooking = async (data, token) => {
  const resp = await axios.post(`${BASE_URL}${BOOKING_URL}/fetchById`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const newBooking = async (token, data) => {
  const resp = await axios.post(`${BASE_URL}${BOOKING_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const updateBooking = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${BOOKING_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const cancelBooking = async (data, token) => {
  const resp = await axios.delete(`${BASE_URL}${BOOKING_URL}`, {
    headers: {
      Authorization: token,
    },
    data,
  });
  return resp.data;
};

const getCurrBooking = async (token) => {
  const resp = await axios.get(`${BASE_URL}${BOOKING_URL}/currBooking`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const bookingService = {
  fetchBookings,
  fetchOneBooking,
  newBooking,
  updateBooking,
  cancelBooking,
  getCurrBooking,
};

export default bookingService;
