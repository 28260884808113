import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authServices from "../services/authService";

const initialState = {
  completed: false,
  userExists: false,
  user: {
    id: "",
    img: "",
    name: "",
    email: "",
    phoneNumber: "",
  },
  accessToken: null,
  status: "loading",
  message: "",
  screen: "login",
};

export const getUserDetails = createAsyncThunk("auth/getUser", async (token, thunkAPI) => {
  try {
    return await authServices.getUser(token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const checkUserAavailable = createAsyncThunk("auth/checkUser", async (data, thunkAPI) => {
  try {
    return await authServices.checkUser(data);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const verifyOtp = createAsyncThunk("auth/verifyOtp", async (data, thunkAPI) => {
  try {
    const phone = thunkAPI.getState().auth.user.phoneNumber;
    return await authServices.verifyOtp({
      phoneNumber: phone,
      otp: data,
    });
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const register = createAsyncThunk("auth/register", async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.accessToken;
    return await authServices.register(data, token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const updateUser = createAsyncThunk("auth/updateUser", async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.accessToken;
    return await authServices.update(data, token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
      return await authServices.updateUserProfile(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLocalPhone: (state, action) => {
      state.phoneNumber = action.payload.phoneNumber;
    },
    setScreen: (state, action) => {
      state.screen = action.payload;
    },
    logout: (state) => {
      Object.assign(state, initialState);
      localStorage.removeItem("ThrillFactoryAccessToken");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = "user check success";
        state.userExists = true;
        state.accessToken =
          action.payload.token || localStorage.getItem("ThrillFactoryAccessToken");
        state.user.id = action.payload.result._id;
        state.user.phoneNumber = action.payload.result.phoneNumber;
        if (!action.payload.result.username) {
          state.completed = false;
          state.screen = "register";
        } else {
          state.user.name = action.payload.result.username;
          state.user.email = action.payload.result.email;
          state.user.img = action.payload.result.profile_url;
          state.completed = true;
          state.screen = "";
        }
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.status = "rejected";
        state.userExists = false;
        state.user.id = "";
        state.user.phoneNumber = "";
        state.user.name = "";
        state.user.email = "";
        state.user.img = "";
        state.message = action.payload;
        state.completed = false;
        state.screen = "login";
      })
      .addCase(checkUserAavailable.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkUserAavailable.fulfilled, (state, action) => {
        state.status = "user check success";
        state.user.phoneNumber = action.payload.phoneNumber;
        state.userExists = action.payload.userExisted;
        state.screen = "verify";
      })
      .addCase(checkUserAavailable.rejected, (state, action) => {
        state.status = "rejected";
        state.user.phoneNumber = "";
        state.userExists = false;
        state.message = action.payload;
        state.screen = "login";
      })
      .addCase(verifyOtp.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = "user verified success";
        if (state.userExists) {
          if (
            action.payload.message.includes("wrong") ||
            action.payload.message.includes("expired")
          ) {
            state.message = action.payload.message;
          } else {
            state.accessToken = action.payload.token;
            state.user.id = action.payload.user._id;
            state.user.name = action.payload.user.username;
            state.user.email = action.payload.user.email;
            state.user.img = action.payload.user.profile_url;
            state.completed = true;
            state.screen = "";
          }
        } else {
          if (
            action.payload.message.includes("wrong") ||
            action.payload.message.includes("expired")
          ) {
            state.message = action.payload.message;
          } else {
            state.accessToken = action.payload.token;
            state.user.id = action.payload.user._id;
            state.screen = "register";
          }
        }
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = "rejected";
        state.message = action.payload;
        state.user.id = "";
        state.user.name = "";
        state.user.email = "";
        state.user.img = "";
        state.completed = false;
        state.screen = "verify";
      })
      .addCase(register.pending, (state) => {
        state.status = "loading";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.status = "user registered success";
        state.userExists = true;
        state.user.name = action.payload.user.username;
        state.user.email = action.payload.user.email;
        state.user.img = action.payload.user.profile_url;
        state.completed = true;
        state.screen = "";
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "rejected";
        state.userExists = false;
        state.user.name = "";
        state.user.email = "";
        state.message = action.payload;
        state.completed = false;
        state.screen = "register";
      })
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "user registered success";
        state.user.name = action.payload.user.username;
        state.user.email = action.payload.user.email;
        state.user.phoneNumber = action.payload.user.phoneNumber;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action.payload.message;
        state.user.img = action.payload.url;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = "rejected";
        state.message = action.payload.message;
      });
  },
});

export const { setLocalPhone, setScreen, logout } = authSlice.actions;

export default authSlice.reducer;
