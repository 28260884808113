import { useDispatch } from "react-redux";
import RoutesWrapper from "./RoutesWrapper";
import Loader from "./components/common/Loader";
import React, { useEffect, Suspense } from "react";
import ModalProvider from "./store/modal/ModalProvider";
import { getAllActivities, getAllMetaDetails } from "./store/slices/activitiesSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllActivities()).then(() => dispatch(getAllMetaDetails()));
  }, [dispatch]);

  return (
    <ModalProvider>
      <Suspense fallback={<Loader />}>
        <RoutesWrapper />
      </Suspense>
    </ModalProvider>
  );
}

export default App;
