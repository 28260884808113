import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cartService from "../services/cartService";

const initialState = {
  id: "",
  items: [],
  numberOfItems: 0,
  totalAmount: 0,
  originalAmount: 0,
  // couponCode: "",
  status: "loading",
  message: "",
  // recommended: [],
};

// export const fetchCart = createAsyncThunk("get/cart", async (data, thunkAPI) => {
//   try {
//     const token =
//       thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
//     return await cartService.fetchCart(token);
//   } catch (e) {
//     const msg =
//       (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
//     return thunkAPI.rejectWithValue(msg);
//   }
// });

// export const addCartItem = createAsyncThunk("add/cartItem", async (data, thunkAPI) => {
//   try {
//     const token =
//       thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
//     // const id = thunkAPI.getState().cart.id;
//     // if (id === "") {
//     return await cartService.addCartItem(data, token);
//     // } else {
//     // return await cartService.updateCart(data, token);
//     // }
//   } catch (e) {
//     const msg =
//       (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
//     return thunkAPI.rejectWithValue(msg);
//   }
// });

export const updateCartItem = createAsyncThunk("update/cartItem", async (data, thunkAPI) => {
  try {
    const token =
      thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
    return await cartService.updateCartItem(data, token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

// export const removeCartItem = createAsyncThunk("remove/cartItem", async (data, thunkAPI) => {
//   try {
//     const token =
//       thunkAPI.getState().auth.accessToken || localStorage.getItem("ThrillFactoryAccessToken");
//     return await cartService.removeCartItem(data, token);
//   } catch (e) {
//     const msg =
//       (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
//     return thunkAPI.rejectWithValue(msg);
//   }
// });

export const removeCart = createAsyncThunk("delete/cart", async (token, thunkAPI) => {
  try {
    return await cartService.removeCart(token);
  } catch (e) {
    const message =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const cartSlices = createSlice({
  name: "myCart",
  initialState,
  reducers: {
    addCartItem: (state, action) => {
      if (action.payload) {
        const cart = JSON.parse(localStorage.getItem("Cart"));
        const itemExists = cart?.items?.find(
          (cartItem) => cartItem.activityId === action.payload.activityId,
        );
        if (itemExists) {
          itemExists.total_item_price += action.payload.activity.activity_price;
          itemExists.person += action.payload.person;
          cart?.items?.map((cartItem) => {
            if (cartItem.activityId === itemExists.activityId) return (cartItem = itemExists);
          });
          cart.totalAmount += action.payload.activity.activity_price;
          localStorage.setItem("Cart", JSON.stringify(cart));
        } else {
          state.items.push(action.payload);
          state.numberOfItems += 1;
          state.totalAmount += action.payload.activity.activity_price;
          localStorage.setItem("Cart", JSON.stringify(state));
        }
      }
    },
    removeCartItem: (state, action) => {
      state.items = state.items.filter((item) => item._id !== action.payload._id);

      state.numberOfItems -= 1;
      state.totalAmount -= action.payload.total_item_price;
      localStorage.setItem("Cart", JSON.stringify(state));
    },
    fetchCart: (state, action) => {
      const localData = JSON.parse(localStorage.getItem("Cart"));
      if (localData) {
        state.items = localData?.items;
        state.totalAmount = localData?.totalAmount;
        state.numberOfItems = localData?.numberOfItems;
      }
    },
    updatePerson: (state, action) => {
      const itemId = action.payload.itemId;

      const itemExists = state.items.find((item) => item._id === itemId);

      if (!itemExists) {
        return;
      }

      itemExists.person = action.payload.person;
      if (action.payload.increment) {
        itemExists.total_item_price += itemExists.activity.activity_price;
        state.totalAmount += itemExists.activity.activity_price;
      } else if (!action.payload.increment && action.payload.person === 0) {
        state.items = state.items.filter((item) => item._id !== itemId);
        state.numberOfItems -= 1;
        state.totalAmount -= itemExists.activity.activity_price;
        localStorage.setItem("Cart", JSON.stringify(state));
      } else {
        itemExists.total_item_price -= itemExists.activity.activity_price;
        state.totalAmount -= itemExists.activity.activity_price;
      }
      localStorage.setItem("Cart", JSON.stringify(state));
    },
    updateMeals: (state, action) => {
      const itemId = action.payload.itemId;
      let itemExists = state.items.find((item) => item._id === itemId);

      if (!itemExists) {
        return;
      }

      const mealId = action.payload.meal._id;
      const mealExists = itemExists.selected_meal.find((meal) => meal._id === mealId);

      if (!mealExists) {
        itemExists.selected_meal.push({
          _id: action.payload.meal._id,
          name: action.payload.meal.name,
          image_url: action.payload.meal.image_url,
          quantity: 1,
          price: action.payload.meal.price,
        });
      } else {
        mealExists.quantity += 1;
      }
      itemExists.meal_price += action.payload.meal.price;
      // if (!mealExists) {
      //   itemExists.selected_meal.push(action.payload.meal);
      //   itemExists.meal_price += action.payload.meal.price;
      // } else {
      //   if (action.payload.increment) {
      //     itemExists.meal_price += mealExists.price;
      //     mealExists.quantity += 1;
      //   } else {
      //     itemExists.meal_price -= mealExists.price;
      //     mealExists.quantity -= 1;
      //   }
      // }
    },
    addActivityDate: (state, action) => {
      state.items.map((item) => (item.activity_date = action.payload.date));
      localStorage.setItem("Cart", JSON.stringify(state));
    },
    removeMeals: (state, action) => {
      const itemId = action.payload.itemId;
      const itemExists = state.items.find((item) => item._id === itemId);

      if (!itemExists) {
        return;
      }

      const mealId = action.payload.meal._id;
      const mealExists = itemExists.selected_meal.find((meal) => meal._id === mealId);

      if (mealExists.quantity === 1) {
        itemExists.selected_meal = itemExists.selected_meal.filter((meal) => meal._id !== mealId);
      } else {
        mealExists.quantity -= 1;
      }
      itemExists.meal_price -= mealExists.price;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchCart.pending, (state) => {
      //   state.status = "loading";
      // })
      // .addCase(fetchCart.fulfilled, (state, action) => {
      //   state.status = "success";
      //   if (action.payload.result !== null) {
      //     state.id = action.payload.result._id;
      //     state.items = action.payload.result.items;
      //     state.numberOfItems = action.payload.result.items?.length;
      //     state.totalAmount = action.payload.result.total_amount;
      //     state.originalAmount = action.payload.result.original_price;
      //     state.recommended = action.payload.recommended;
      //     state.couponCode = action.payload.result.coupon_code;
      //   }
      // })
      // .addCase(fetchCart.rejected, (state, action) => {
      //   state.status = "rejected";
      //   state.items = [];
      //   state.totalAmount = 0;
      //   state.message = action.payload;
      // })
      // .addCase(addCartItem.pending, (state) => {
      //   state.status = "loading";
      // })
      // .addCase(addCartItem.fulfilled, (state, action) => {
      //   state.status = "success";
      //   state.message = action.payload.message;
      // })
      // .addCase(addCartItem.rejected, (state, action) => {
      //   state.status = "rejected";
      // })
      .addCase(updateCartItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCartItem.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action.payload.message;
      })
      .addCase(updateCartItem.rejected, (state, action) => {
        state.status = "rejected";
      })
      // .addCase(removeCartItem.pending, (state) => {
      //   state.status = "loading";
      // })
      // .addCase(removeCartItem.fulfilled, (state, action) => {
      //   state.status = "success";
      //   state.message = action.payload.message;
      // })
      // .addCase(removeCartItem.rejected, (state, action) => {
      //   state.status = "rejected";
      //   state.message = action.payload.message;
      // })
      .addCase(removeCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeCart.fulfilled, (state, action) => {
        state.status = "success";
        state.message = action.payload;
      })
      .addCase(removeCart.rejected, (state, action) => {
        state.status = "rejected";
        state.message = action.payload;
      });
  },
});

export const {
  updateMeals,
  updatePerson,
  removeMeals,
  addCartItem,
  removeCartItem,
  fetchCart,
  addActivityDate,
} = cartSlices.actions;
export default cartSlices.reducer;
