import axios from "axios";
import config from "../../config/config";

const BASE_URL = config.REACT_APP_BASE_URL;
const CART_URL = "public/api/cart";

const fetchCart = async (token) => {
  const resp = await axios.get(`${BASE_URL}${CART_URL}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const updateCart = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${CART_URL}/item`, data, {
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
};

const addCartItem = async (data, token) => {
  const resp = await axios.post(`${BASE_URL}${CART_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const updateCartItem = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${CART_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const removeCartItem = async (data, token) => {
  const resp = await axios.delete(`${BASE_URL}${CART_URL}`, {
    headers: {
      Authorization: token,
    },
    data,
  });
  return resp.data;
};

const removeCart = async (token) => {
  const resp = await axios.delete(`${BASE_URL}${CART_URL}/remove`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const cartServices = {
  fetchCart,
  updateCart,
  addCartItem,
  updateCartItem,
  removeCartItem,
  removeCart,
};

export default cartServices;
