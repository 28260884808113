import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import services from "../services/getServices";

const initialState = {
  activities: [],
  metaDetails: [],
  metaStatus: "loading",
  combos: [],
  status: "loading",
  selectedMeals: [],
  totalMealsAmount: 0,
  currentActivity: {},
  selectedPerson: 1,
  activityDate: "",
  actualPrice: 0,
  discountedPrice: 0,
  message: "",
  allBanners: [],
  allBlogs: [],
};

export const getAllActivities = createAsyncThunk("get/activities", async (thunkAPI) => {
  try {
    return await services.getAllActivities();
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const getAllMetaDetails = createAsyncThunk("get/metaData", async (thunkAPI) => {
  try {
    return await services.getAllMetaDetails();
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

// export const getActivityDetails = createAsyncThunk(
//   "get/activityDetails",
//   async (data, thunkAPI) => {
//     try {
//       return await services.getActivityDetails(data);
//     } catch (e) {
//       const msg =
//         (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
//       return thunkAPI.rejectWithValue(msg);
//     }
//   },
// );

const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    setAllBanners: (state, action) => {
      state.allBanners = action.payload;
    },
    setAllBlogs: (state, action) => {
      state.allBlogs = action.payload;
    },
    addSelectedMeal: (state, action) => {
      const currentMeal = action.payload;
      const mealExists = state.selectedMeals.find((meal) => meal._id === currentMeal._id);

      if (!mealExists) {
        state.selectedMeals.push({
          _id: currentMeal._id,
          name: currentMeal.name,
          quantity: 1,
          price: currentMeal.price,
        });
      } else {
        mealExists.quantity += 1;
      }

      state.totalMealsAmount += currentMeal.price;
    },
    removeSelectedMeals: (state, action) => {
      const mealId = action.payload;
      const mealExists = state.selectedMeals.find((meal) => meal._id === mealId);
      if (!mealExists) {
        return;
      }

      if (mealExists.quantity === 1) {
        state.selectedMeals = state.selectedMeals.filter((meal) => meal._id !== mealId);
      } else {
        mealExists.quantity -= 1;
      }
      state.totalMealsAmount -= mealExists.price;
    },
    clearAllSelection: (state) => {
      state.selectedMeals = [];
      state.totalMealsAmount = 0;
      state.selectedDates = {
        start: "",
        end: "",
      };
      state.selectedPerson = 1;
    },

    updateSelectedPerson: (state, action) => {
      state.selectedPerson = action.payload;
    },

    updateSelectedDate: (state, action) => {
      state.activityDate = action.payload;
    },

    setCurrentActivity: (state, action) => {
      state.currentActivity = action.payload;
    },

    updateActualPrice: (state, action) => {
      state.actualPrice = action.payload.actual;
      state.discountedPrice = action.payload.discounted;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllActivities.fulfilled, (state, action) => {
        state.status = "success";
        state.activities = action.payload.result;
        state.combos = action.payload.combo;
      })
      .addCase(getAllActivities.rejected, (state, action) => {
        state.status = "rejected";
        state.activities = [];
        state.message = action.payload;
      })
      .addCase(getAllMetaDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllMetaDetails.fulfilled, (state, action) => {
        state.metaStatus = "success";
        state.metaDetails = action.payload.result;
      })
      .addCase(getAllMetaDetails.rejected, (state, action) => {
        state.metaStatus = "rejected";
        state.metaDetails = [];
        state.message = action.payload;
      });
    // .addCase(getActivityDetails.pending, (state) => {
    //   state.status = "loading";
    // })
    // .addCase(getActivityDetails.fulfilled, (state, action) => {
    //   state.status = "success";
    //   state.activities = action.payload.result;
    // })
    // .addCase(getActivityDetails.rejected, (state, action) => {
    //   state.status = "rejected";
    //   state.activities = [];
    //   state.message = action.payload;
    // });
  },
});

export const {
  updateSelectedDate,
  updateSelectedPerson,
  addSelectedMeal,
  removeSelectedMeals,
  clearAllSelection,
  setCurrentActivity,
  updateActualPrice,
  setAllBanners,
  setAllBlogs,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
