import { useState } from "react";
import ModalContext from "./ModalContext";

const ModalProvider = (props) => {
    const [showModal, setShowModal] = useState(false);

    const hideModalHandler = () => {
        setShowModal(false);
    };

    const showModalHandler = () => {
        setShowModal(true);
    };

    const modalContext = {
        showModal,
        hideModalHandler,
        showModalHandler,
    };

    return <ModalContext.Provider value={modalContext}>{props.children}</ModalContext.Provider>;
};

export default ModalProvider;
